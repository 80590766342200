import React, { Component } from 'react';
import '../stylesheets/Contact.css';
import '../stylesheets/Background.css';
import { Background } from './Helpers';
import Media from "react-media";
import Navigation from './Navigation';
import Navbar from './Navbar';

class Contact extends Component {

  resetForm(){
    document.getElementById('contactForm').reset();
  }

  render() {
    return (
        <body background={Background()}>
          <Media query="(max-width: 500px)">
              {matches =>
                matches ? (
                  <div>
                    <Navbar></Navbar>
                    <div class="center-screen">
                      <form class="box-mobile" action="https://formcarry.com/s/vX5eKSsq6gk" method="POST" accept-charset="UTF-8" >
                        <h1>Contact Me</h1>
                        <input type="text" name="name" id="Name" placeholder="Name" required=""></input>
                        <input type="email" name="email" id="Email" placeholder="Email" required=""></input>
                        <input type="text" name="subject" id="Subject" placeholder="Subject" required=""></input>
                        <textarea type="text" class="message-mobile" name="message" id="Message" placeholder="Message" required=""></textarea>
                        <button class="submit-mobile" type="submit">Submit</button>
                      </form>
                    </div>    
                  </div>
              ) : (
                <div>
                  <Navigation />
                  <div class="center-screen">
                    <form class="box" action="https://formcarry.com/s/vX5eKSsq6gk" method="POST" accept-charset="UTF-8" >
                      <h1>Contact Me</h1>
                      <input type="text" name="name" id="Name" placeholder="Name" required=""></input>
                      <input type="email" name="email" id="Email" placeholder="Email" required=""></input>
                      <input type="text" name="subject" id="Subject" placeholder="Subject" required=""></input>
                      <textarea type="text" class="message" name="Message" id="message" placeholder="Message" required=""></textarea>
                      <button class="submit" type="submit">Submit</button>
                    </form>
                  </div>
                </div>
              )
              }
          </Media>
        </body>
    );
  }
}

export default Contact;
