import Home from './Home';
import Resume from './Resume';
import Contact from './Contact';
import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

export default () => (
    <Router>
        <Switch>
            <Route path ="/" exact component={Home} />
            <Route path ="/resume" exact component={Resume} />
            <Route path ="/contactme" exact component={Contact} />
        </Switch>
    </Router>
)