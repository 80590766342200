import React, { Component } from 'react';
import '../stylesheets/Navbar.css';
import { stack as Menu } from 'react-burger-menu'
import '@trendmicro/react-sidenav/dist/react-sidenav.css';

class Navbar extends Component {
  
  render () {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
      <Menu right width={'100%'} isOpen={false}>
        <a className="menu-item" href="/">Home</a>
        <a className="menu-item" href="https://www.linkedin.com/in/aneeshsoni">Linked In</a>
        <a className="menu-item" href="/resume">Résumé</a>
        <a className="menu-item" href="https://www.youtube.com/channel/UCmybcG7EQlh2VBZzNE80h_w">YouTube</a>
        <a className="menu-item" href="/contactme">Contact Me</a>
      </Menu>
    );
  }
}

export default Navbar;
