import React from 'react';
import {Link} from 'react-router-dom';
import '../stylesheets/Home.css';
import '../stylesheets/Background.css';
import Media from "react-media";
import { Background } from './Helpers';
import Navbar from './Navbar';

function Home(props) {

  console.log(props);

  return (
    <body background={Background()}>
      <Media query="(max-width: 500px)">
        {matches =>
          matches ? 
          (
            <div>
              <Navbar></Navbar>
              <div class="center-screen-mobile">
                <h1 class="mobile-name">Aneesh Soni</h1>
              </div>
            </div>
          ) 
          : 
          (
            <div>        
              <div class="center-screen">
                <h1 class="name">Aneesh Soni</h1>
                <ol class="ch-grid">
                  <li>
                    <a class="ch-item ch-img-1" href="https://www.linkedin.com/in/aneeshsoni">
                      <div class="ch-info">
                        <h3>Linked In</h3>
                      </div>
                    </a>
                  </li>

                  <li>
                    <Link to="resume" class="ch-item ch-img-2">
                      <div class="ch-info">
                        <h3>Résumé</h3>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <a class="ch-item ch-img-4" href="https://www.youtube.com/channel/UCmybcG7EQlh2VBZzNE80h_w">
                      <div class="ch-info">
                        <h3>YouTube</h3>
                      </div>
                    </a>
                  </li>
                  
                  <li>
                    <Link to="contactme" class="ch-item ch-img-3">
                      <div class="ch-info">
                        <h3>Contact</h3>
                      </div>
                    </Link>
                  </li>

                </ol>
              </div>
            </div>
          )
        }
      </Media>
    </body>
  );
};

export default Home;