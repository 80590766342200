import React, { Component } from 'react';
import '../stylesheets/Navigation.css';
import {Link} from 'react-router-dom';

class Navigation extends Component {

  render() {
    return (
      <div class="name-nav">
        <Link to="/" class="link">Aneesh Soni</Link>
      </div>
    );
  }
}

export default Navigation;
