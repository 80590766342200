import React, { Component } from 'react';
import '../stylesheets/Resume.css';
import '../stylesheets/Background.css';
import resume from '../files/Resume.pdf';
import resume_no_link from '../files/Resume.pdf';
import { Background } from './Helpers';
import Navigation from './Navigation';
import Navbar from './Navbar';
import Media from "react-media";
import { Document, Page, pdfjs} from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class Resume extends Component {

    state = {
        numPages: null,
        pageNumber: 1,
        scale: 1
    }
     
    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    render() {

        return (
            <div>
                <body background={Background()}>
                    <Media query="(max-width: 500px)">
                        {matches =>
                            matches ? 
                            (
                            <div>
                                <Navbar></Navbar>    
                                <div class="center-resume">
                                    <div>
                                        <Document file={resume_no_link} onLoadSuccess={this.onDocumentLoadSuccess}>
                                            <Page pageNumber={1} width={350}/>
                                        </Document>
                                    </div>
                                        <form method="get" action={resume}>
                                            <div>
                                                <button class="btn-mobile"><i class="fa fa-download"></i>Download</button>
                                            </div>
                                        </form>
                                </div>
                            </div>
                            )
                            :
                            (
                            <div>
                                <Navigation />
                                <div class="center-resume">
                                    <embed src={resume} width="800px" height="2100px" />
                                    <form method="get" action={resume}>
                                        <div>
                                            <button class="btn"><i class="fa fa-download"></i>Download</button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                            )}
                    </Media>
                </body>
            </div>
        );
    }
}

export default Resume;